import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import locales from "../../../constants";

const Virego = ({ pageContext: { slug } }) => {
  const lang = "en";
  const nav = {
    initialSlideIndex: 1,
  };
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #927A48, #A68C59, #BDA26D, #A68C59, #927A48 )",
        icons: "#caae76",
        navClass: "virego",
      }}
      seo={{
        title: "Virego",
        headerTitle: "virego",
        href: slug,
        lang: "en",
        ogImage: require("../../../assets/img/portfolio/virego_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/virego/",
      }}
    >
      {/* eslint-disable jsx-a11y/media-has-caption */}
      <PortfolioHeader name="virego" height="86" />
      <section className="container-fluid virego_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Virego</h1>
              <ul>
                <li>Logo</li>
                <li>Brand Book</li>
                <li>Website</li>
                <li>Advertising Materials</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                VIREGO® has been operating on the business services market since
                2012. The core of company's activities is worldwide support and
                development of entrepreneurs in various processes and business
                areas. Thanks to an international network of partners covering
                over 11 countries and 200 specialists, VIREGO® solves the most
                complex problems of clients and actively participates in the
                development of many companies, providing concierge services.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid virego_section_3">
        <div className="row">
          <div className="col-md-6 col_1">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/virego_main_1.png")}
              alt=""
            />
            <div className="img_wrapper">
              <img
                className="img-fluid"
                src={require("../../../assets/img/portfolio/virego_main_2.png")}
                alt=""
              />
            </div>
          </div>
          <div className="col-md-4 offset-md-2 col_2">
            <h3>Logo</h3>
            <div className="row">
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>Black CMYK</p>
                    <h5>
                      <span>0</span>
                      <span>0</span>
                      <span>0</span>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 100 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>Gold CMYK</p>
                    <h5>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 22 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 28 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 62 : 0}
                      />
                      <CountUp start={0} duration={3} end={isVisible ? 1 : 0} />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <div className="col-sm-6">
                <p>RGB</p>
                <h5>
                  <span>0</span>
                  <span>0</span>
                  <span>0</span>
                </h5>
              </div>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>RGB</p>
                    <h5>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 203 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 176 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 118 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>000000</h5>
              </div>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>cbb076</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="virego_section_4">
        <h4>Visual Identification</h4>
      </section>
      <section className="virego_section_5">
        <img
          src={require("../../../assets/img/portfolio/virego_main_3.png")}
          alt=""
        />
      </section>
      <section className="virego_section_6">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="inner">
              <h4>Website</h4>
              <p>
                We have completely rebranded VIREGO®. We have created new
                business forms that reflect the global ambitions of the company,
                including the logo and Brand Book. We were also responsible for
                preparing all elements of the visual identity of the company, as
                well as for a new, modern website, and its animations.
              </p>
              <p>
                The project has been developed with a new brand and the project
                website can be viewed at Voeld.com.
              </p>
              <br />
              <a
                href="https://voeld.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn_custom"
              >
                Show project
              </a>
            </div>
          </div>
          <div className="col-md-6 text-right">
            <div className="scroll_wrapper">
              <span />
              <div className="img_wrapper">
                <img
                  src={require("../../../assets/img/portfolio/virego_entire_page.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="virego_section_7">
        <img
          src={require("../../../assets/img/portfolio/virego_main_5.png")}
          alt=""
        />
      </section>
      <section className="virego_section_8">
        <img
          src={require("../../../assets/img/portfolio/virego_main_6.png")}
          alt=""
        />
      </section>
      <section className="virego_section_9">
        <img
          src={require("../../../assets/img/portfolio/virego_main_7.png")}
          alt=""
        />
      </section>
      <section className="virego_section_10">
        <video loop autoplay="true">
          <source
            src="https://virego.pl/wp-content/themes/virego/video/VIREGO_WOMAN_v4.mp4"
            type="video/mp4"
          />
        </video>
      </section>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};

export default Virego;
